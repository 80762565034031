import { AppAbility, getAbilities, Subjects } from "@/plugins/casl";
import { Collection, CollectionInfo, Links, Model } from "@/store/models";
import { isPopulatable } from "@/store/models/abstractModel";
import { AxiosRequestConfig } from "axios";
import { API, getAPI } from "../api";

export type RepositoryOptions<M extends Model> = {
  endpoint?: string;
  subject: Subjects;
  type: string;
  subType?: string;
  TypeConstructor: new (data?: Partial<M>) => M;
};

export interface RepositoryFilters {
  page?: number;
  size?: number;
  sort?: string;
  getURLFilters(): string;
}

export class StandardRepositoryFilters implements RepositoryFilters {
  page?: number;
  size?: number;
  sort?: string;
  getURLFilters(): string {
    const URL: string[] = [];
    Object.entries(this).forEach((property) => {
      if (property[1] !== undefined) {
        let result = `${property[0]}=${encodeURIComponent(
          String(property[1])
        )}`;
        if (Array.isArray(property[1])) {
          result = `${property[0]}=${encodeURIComponent(
            property[1].join(",")
          )}`;
        }
        URL.push(result);
      }
    });
    return `?${URL.join("&")}`;
  }
}

export class SearchRepositoryFilters extends StandardRepositoryFilters {
  search?: string;
}

export class UsersRepositoryFilters extends SearchRepositoryFilters {
  descendant?: boolean;
  userIds?: string[];
  groupIds?: string[];
}

export type CollectionNextLoader<M extends Model> = {
  (Collection: Collection<M>): Promise<Collection<M>>;
};

export interface Repository<M extends Model> {
  getById(id: string): Promise<M>;
  find(filters?: RepositoryFilters): Promise<Collection<M>>;
  save(model: M): Promise<M>;
  delete(model: M): Promise<M>;
}

export class AbstractRepository<M extends Model> implements Repository<M> {
  protected options: RepositoryOptions<M>;

  protected api: API;

  protected abilities: AppAbility;

  protected FormData: string[] = [];

  constructor(options: RepositoryOptions<M>) {
    this.options = options;
    this.api = getAPI(this.options.type);
    this.abilities = getAbilities();
  }

  get endpoint(): string {
    let result = this.options.endpoint || "";
    if (this.options.endpoint) {
      const regDynamicAttributes = /\{([\w]+)\}/g;
      const dynamicsAttributes = [...result.matchAll(regDynamicAttributes)];
      if (dynamicsAttributes.length !== 0) {
        dynamicsAttributes.forEach((value) => {
          const attribute = Object.entries(this).find(
            (attr) => attr[0] === value[1]
          );
          if (attribute === undefined) {
            throw new Error(
              `Endpoint definition failed. '${value[0]}' have no match in the object.`
            );
          }
          result = attribute[1]
            ? result.replaceAll(value[0], attribute[1])
            : result.replaceAll(`/${value[0]}`, "");
        });
      }
    }
    if (result.startsWith("?") || result.length == 0) {
      return this.api.options.basePath + result;
    } else {
      return this.api.options.basePath + "/" + result;
    }
  }

  public getById(id: string): Promise<M> {
    if (id === "") {
      return Promise.reject(new Error("Missing id parameter"));
    }
    return this.api.client.get(`${this.endpoint}/${id}`).then((response) => {
      return Promise.resolve(this.createModel(response.data));
    });
  }

  public find(filters?: RepositoryFilters): Promise<Collection<M>> {
    const urlFilters = filters ? filters.getURLFilters() : "";
    return this.api.client
      .get(`${this.endpoint}${urlFilters}`)
      .then((response) => {
        const data = { ...response.data },
          filteredData = { ...data };
        delete filteredData["_embedded"];
        delete filteredData["page"];
        delete filteredData["_links"];

        return Promise.resolve(
          this.createCollection(
            // eslint-disable-next-line
            (data._embedded === undefined) ? [] : data._embedded[this.options.subType || this.options.type],
            data.page,
            // eslint-disable-next-line
            data._links,
            Object.keys(filteredData).length > 0 ? filteredData : undefined
          )
        );
      });
  }

  public save(model: M): Promise<M> {
    let msg: string;
    if (model.id) {
      if (!this.abilities.can("update", this.options.subject)) {
        msg = `Update ${
          this.options.subType || this.options.type
        } is not allowed`;
        console.error(msg, model.id);
        return Promise.reject(new Error(msg));
      }
      return this.api.client
        .patch(
          `${this.endpoint}/${model.id}`,
          this.getRequestData(model),
          this.getRequestOptions(model)
        )
        .then((response) => {
          return Promise.resolve(this.createModel(response.data));
        });
    }

    if (!this.abilities.can("create", this.options.subject)) {
      msg = `Create ${
        this.options.subType || this.options.type
      } is not allowed`;
      return Promise.reject(new Error(msg));
    }

    return this.api.client
      .post(
        `${this.endpoint}`,
        this.getRequestData(model),
        this.getRequestOptions(model)
      )
      .then((response) => {
        return Promise.resolve(this.createModel(response.data));
      });
  }

  private getRequestData(model: M): Record<string, unknown> | FormData {
    if (this.FormData.length === 0) {
      return model.toJSON();
    }
    let empty = false;
    this.FormData.forEach((field: string) => {
      const attr = (model as Record<string, unknown>)[field] as Blob;
      if (attr === undefined) {
        empty = true;
      }
    });

    if (empty) {
      return model.toJSON();
    }

    const data = new FormData();
    const str = JSON.stringify(model.toJSON());
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });
    data.append(`${model.type.toLowerCase()}`, blob);
    this.FormData.forEach((field: string) => {
      const file = (model as Record<string, unknown>)[field] as Blob;
      data.append(field, file);
    });

    return data;
  }

  private getRequestOptions(model: M): AxiosRequestConfig | undefined {
    if (this.FormData.length === 0) {
      return;
    }

    let empty = false;

    this.FormData.forEach((field: string) => {
      const attr = (model as Record<string, unknown>)[field] as Blob;
      if (attr === undefined) {
        empty = true;
      }
    });

    if (empty) {
      return;
    }

    return { headers: { "Content-Type": "multipart/form-data" } };
  }

  public delete(model: M): Promise<M> {
    let msg: string;
    if (model.id === undefined) {
      msg = `ID attribute is missing for ${
        this.options.subType || this.options.type
      } model`;
      console.error(msg, model);
      return Promise.reject(new Error(msg));
    }

    if (!this.abilities.can("delete", this.options.subject)) {
      msg = `Delete ${
        this.options.subType || this.options.type
      } model is not allowed`;
      console.error(msg, model.id);
      return Promise.reject(new Error(msg));
    }

    return this.api.client
      .delete(`${this.endpoint}/${model.id}`)
      .then(() => {
        return Promise.resolve(model);
      })
      .catch((reason) => {
        msg = `An error was occurred during deletion of the ${
          this.options.subType || this.options.type
        }`;
        console.error(msg, model.id, reason);
        throw new Error(msg);
      });
  }

  protected createModel(content: Partial<M>): M {
    const model = new this.options.TypeConstructor();
    Object.assign(model, content);
    if (isPopulatable(model)) {
      model.populate(content);
    }
    return model;
  }

  protected createCollection(
    content: Partial<M>[],
    info: Partial<CollectionInfo>,
    links: Partial<Links>,
    furtherData?: Record<string, unknown>
  ): Collection<M> {
    return new Collection(
      content.map((modelContent) => this.createModel(modelContent)),
      new CollectionInfo(info),
      new Links(links),
      this.collectionNextLoader(),
      furtherData
    );
  }

  protected collectionNextLoader(): CollectionNextLoader<M> {
    return (collection: Collection<M>): Promise<Collection<M>> => {
      let msg: string;
      if (collection.links.next.href === undefined) {
        msg = "Current collection have no next children";
        console.error(msg, collection);
        return Promise.reject(new Error(msg));
      }
      return this.api.client
        .get(collection.links.next.href)
        .then((response) => {
          const content: Partial<M>[] =
            response.data._embedded[this.options.subType || this.options.type];
          if (content && content.length > 0) {
            return Promise.resolve(
              new Collection(
                content.map((modelContent) => this.createModel(modelContent)),
                new CollectionInfo(response.data.page),
                // eslint-disable-next-line
                new Links(response.data._links)
              )
            );
          }
          msg = "Current collection have received no next children";
          console.error(msg);
          throw new Error(msg);
        });
    };
  }
}
