import { AbstractModel } from ".";
import Position from "./position";
import Slot from "./slot";

export enum TribeInformationStatus {
  Archived = 0,
  Edited = 10,
  Published = 20,
}

export default class TribeInformation extends AbstractModel {
  type = "TribeInformation";
  id?: string;

  address?: string;

  callbackUrl?: string;

  city?: string;

  country?: string;

  description?: string;

  image?: string | File;

  isExternal?: boolean;

  isSearchable?: boolean;

  logo?: string | File;

  messagingId?: string;

  name?: string;

  openSlots?: Slot[];

  organizationId?: string;

  position?: Position;

  postalCode?: string;

  privateMessagingEnabled?: boolean;

  smsBroker?: string;

  smsSender?: string;

  status?: TribeInformationStatus;

  tags?: string[];

  welcome?: string;

  adminGroup?: string | null;

  // eslint-disable-next-line
  public toJSON(toExclude?: string[]): Record<string, any> {
    return super.toJSON(["logo", "image"]);
  }
}
